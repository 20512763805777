.FTC-header {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    justify-content: center;
    background-image: 
    linear-gradient(rgb(0,0,0, 0.5), rgb(0,0,0, 0.5)),
    url('../../Assets/FTC/Header.png');
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20em;
    max-height: 20em;
    gap: 1.5em;
    h1 {
        color: white;
        font-size: 4em;
    }
    h2 {
        color: white;
        font-weight: 400;
        font-style: italic;
        font-size: 1.15em;
    }
}


.FTC-content {

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        padding-top: 1em;
    }

    hr {
        margin: 0 auto;
        margin-top: 1em;
        border-color: black;
        width: 10em;
    }

    .section1 {
        padding-bottom: 2.5em;
        p {
            padding: 0em 10vw;
            padding-top: 1em;
            font-weight: 400;
            font-size: 20px;
        }
        video {
            margin-top: 3em;
            height: 30vw;
            border-radius: 10px;
        }
        .small {
            font-size: 1.5em;
            font-weight: 400;
            font-style: italic;
        }
    }

    .section2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 2.5em;
        width: 100%;
        background-color: rgb(255, 230, 0);
        .underhead {
            margin-top: 1em;
        }
        .flex-grid {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 3em;
            .row {
                display: flex;
                flex-direction: row;
            }
            .col {
                display: flex;
                flex-direction: row;
                flex: 1;
            }
            .sponsor-card {
                margin: 2vw 3vw;
            }
        }
        .mobile1  {
            display: none;
        }
        .mobile2  {
            display: none;
        }
        .mobile3  {
            display: none;
        }
    }

    .section3 {
        background-color: white;
        width: 100%;
        .awards-button {
            margin-top: 2em;
            background-color: rgb(37, 37, 37);
            border-radius: 5px;
            padding: 0.5em 4em;
            color: white;
            font-weight: bold;
            font-size: 16px;
            bottom: 15%;
            text-transform: uppercase;
            transition: all .25s;
            &:hover {
                color: rgb(255, 230, 0);
            }
            margin-bottom: 2em;
        }
        h3 {
            margin-top: 2em;
            font-weight: 400;
        }
    }
}

@media (max-width: 925px) {
    .FTC-header {
        h1 { font-size: 3em;}
    }
}

@media (max-width: 700px) {
    .FTC-content {
        .section1 {
            video {
                height: 50vw;
            }
        }
    }
}

@media (max-width: 400px) {
    .FTC-content {
        .section1 {
            video {
                height: 50vw;
            }
        }
    }
}

// GRID

@media only screen and (max-width: 1700px) {
    .FTC-content {
        .section2 {
            .web {
                display: none;
            }
            .mobile1 {
                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .FTC-content {
        .section2 {
            .web {
                display: none;
            }
            .mobile1 {
                display: none;
            }
            .mobile2 {
                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    .FTC-content {
        .section2 {
            .web {
                display: none;
            }
            .mobile1 {
                display: none;
            }
            .mobile2 {
                display: none;
            }
            .mobile3 {
                display: flex;
            }
        }
    }
}