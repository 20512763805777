$background-primary: rgb(228, 228, 228);
$card-height: 20em;
$card-width: 20em;

.sponsor-card {

    width: $card-width;
    height: $card-height;
    float: left;
    perspective: 500px;
    display: flex;

    .content {
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
        transition: transform 1s;
        transform-style: preserve-3d;
    }

    &:hover .content {
        transform: rotateY(180deg);
        transition: transform 0.5s;
    }

    .front, .back {
        background-color: white;
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;
        border-radius: 5px;
        backface-visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            padding: 0.5vw;
        }
    }

    .back {
        background: white;
        color: black;
        transform: rotateY( 180deg );
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 2em;;
        padding: 1em;
        h1 {
            margin-top: 0em;    
            font-size: 1.15em;
        }
        p {
            font-size: 1em;
        }
        a {
            color: black;
            text-decoration: none;
            position: absolute;
            bottom: 10%;
            background-color: gray;
            padding: 0.5em 3.5em;
            border: 2px solid black;
            transition: all 0.5s;
            font-size: 1em;
            .redirect-icon {
                margin-right: 1em;
                display: none;
                position: absolute;
                left: 0.75em;
                margin-top: 0.15em;
            }
            &:hover {background-color: rgb(255, 230, 0);}
            &:hover > .redirect-icon {display: inline;}
        }
        .role {
            font-weight: bold;
            position: absolute;
            bottom: 2em;
        }
    }

}



