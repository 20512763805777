.contentPrompt {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(77, 77, 77, 0.445);
    left: 0;
    top: 0;
    z-index: 10000;
    padding: 10vw;
}

/* Customizable */

#CampA, #CampB {
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        overflow: scroll;
        width: 70vw;
        height: 70vh;
        padding: 2vw;
        border-radius: 10px;
        background-color: white;
        .title  {
            margin: 0 auto;
            margin-bottom: 1em;
        }
        .close {
            display: flex;
            position: absolute;
            background-color: transparent;
            border: none;
            transition: all 0.2s;
            border-radius: 20rem;
            padding: 0.35rem;
            right: 3em;
            top: 2vw;
            &:hover {
                background-color: rgba(128, 128, 128, 0.452);
            }
        }
        .description {
            text-align: center;
            margin-top: 1em;
            margin-bottom: 1em;
        }
        .header {
            margin: 0 auto;
            margin-bottom: 1em;
            margin-top: 1em;
        }


        table {
            border: 2px solid black;
            border-radius: 10px;
            border-collapse: collapse;
        }

        th {
            font-size: 24px;;
            padding: 1em;
            border-right: solid 1px black; 
            border-left: solid 1px black;
        }

        td {
            padding: 1em;
            border-right: solid 1px black; 
            border-left: solid 1px black;
            text-align: center;
        }
        
        tr {
            border-bottom: 1px solid black;
        }

        ul {
            margin-left: 1em;
        }

        li {
            font-size: 0.95em;
            margin: 0.2em;
        }

        hr {
            width: 100%;
        }

    }
}