@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');


* {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
}

button, a {
    cursor: pointer;
}