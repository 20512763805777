.ImageSlider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .image {
        width: 600px;
        height: 400px;
        border-radius: 10px;
        z-index: 1;
    }
    .right-arrow {
        top: 50%;
        font-size: 3rem;
        z-index: 2;
        user-select: none;
        cursor: pointer;
        margin-left: 3vw;
    }
    .left-arrow {
        top: 50%;
        font-size: 3rem;
        z-index: 2;
        user-select: none;
        cursor: pointer;
        margin-right: 3vw;
    }
    .slide {
        opacity: 0;
        transition-duration: 1s ease;
    }
    .slide.active {
        opacity: 1;
        transition-duration: 1s;
        transform: scale(1.05);
    }
}