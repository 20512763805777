.sponsor-header {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    justify-content: center;
    background-image: 
    linear-gradient(rgb(0,0,0, 0.5), rgb(0,0,0, 0.5)),
    url('../../Assets/Sponsors/PageContent/Header.JPG');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20em;
    max-height: 20em;
    gap: 1.5em;
    h1 {
        color: white;
        font-size: 4em;
    }
    h2 {
        color: white;
        font-weight: 400;
        font-style: italic;
        font-size: 1.15em;
    }
}

.sponsor-instructions {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 1em 0em;
    font-weight: 600;
    font-size: 1.75em;
}

.sponsor-container {
    display: flex;                                                             
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vw;
    margin-top: 2.5vw;
    .row {
        display: flex;
        flex-direction: row;
    }
    .sponsor-card {
        margin: 2vh 2vw;
    }
}

.mobile1 {
    .sponsor-card {
        margin: 2vh 3vw;
    }
    display: none;
}

.mobile2 {
    display: none;
}

.mobile3 {
    display: none;
}

.sponsor-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(255, 217, 0);
    padding: 3em 5vw;
    padding-bottom: 0;
    .graphic {
        height: 15vw;
    }
    .column {
        display: flex;
        flex-direction: column;
        gap: 2em;
        a {
            background-color: inherit;
            text-align: center;
            text-decoration: none;
            border: 2px solid black;
            padding: 0.5em 0em;
            max-width: 10em;
            margin-bottom: 2em;
            font-weight: bold;
            color: black;
            transition: all .25s;
            &:hover {
                background-color: white;
            }
        }
        p {
            max-width: 50vw;
            font-size: 1.25em;
            font-weight: 300;
        }
    }
}

@media only screen and (max-width: 1565px) {
    .web {
        display: none;
    }
    .mobile1 {
        display: flex;
    }
}

@media only screen and (max-width: 1200px) {
    .web {
        display: none;
    }
    .mobile1 {
        display: none;
    }
    .mobile2 {
        display: flex;
    }
    .sponsor-footer {
        padding-bottom: 2em;
        .graphic {
            display: none;
        }
        a{
            display: none;
        }
        .column {
            p  {
                max-width: 80vw;
                font-size: 1em;;
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    .web {
        display: none;
    }
    .mobile1 {
        display: none;
    }
    .mobile2 {
        display: none;
    }
    .mobile3 {
        display: flex;
    }
}